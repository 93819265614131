export function getSelectedVariant(
  propertyValues: Array<{ property_id: number; value_ids: Array<number> }>,
  valuesVariants: Record<string, string>,
  variantValues: Record<number, Array<number>>,
  valuesPositions: Record<number, number>,
  selectedPropertyIndex: number,
  newSelectedValue: Array<number | string>
): string {
  let selectedValuePath = newSelectedValue.join(',')
  if (!valuesVariants[selectedValuePath]) {
    const distances = []
    const rightPart = this.range(selectedPropertyIndex + 1, propertyValues.length - 1, 1)
    const leftPart = this.range(0, selectedPropertyIndex - 1, 1)
    const orderedIndexes = rightPart.concat(leftPart)
    Object.values(variantValues).forEach((values) => {
      if (values[selectedPropertyIndex] == newSelectedValue[selectedPropertyIndex]) {
        let distance = 0
        let changedProperties = 0
        orderedIndexes.forEach((position, count) => {
          if (values[position] != newSelectedValue[position]) {
            changedProperties += 1
            distance = 10000 * count + valuesPositions[values[position]] - valuesPositions[newSelectedValue[position]]
          }
        })
        distances.push({
          distance: distance,
          changedProperties: changedProperties,
          variantPath: values.join(','),
        })
      }
    })
    distances.sort((d1, d2) => {
      const diffChangedProperties = d1.changedProperties - d2.changedProperties
      if (diffChangedProperties != 0) return diffChangedProperties
      return d1.distance - d2.distance
    })
    selectedValuePath = distances[0].variantPath
  }
  return valuesVariants[selectedValuePath]
}
