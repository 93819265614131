

import { CF2Component } from 'javascript/lander/runtime'

export default class SelectBoxV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
      const select = this.element.querySelector('.elSelect');
      const label = this.element.querySelector('.elLabel');

      this.addSelectEventHandlers(select)
      this.labelDisplayer(select.value)
    }

    labelDisplayer(value){
      if (value && value != '') {
        this.element.classList.add('hasValue')
      } else {
        this.element.classList.remove('hasValue')
      }
    }

    addSelectEventHandlers(select){
      select.addEventListener('focus', () => {
        this.element.querySelector('[data-input-status-type]').innerHTML = ''
        this.element.classList.remove("elInputValid")
        this.element.classList.remove("elInputError")
        this.element.classList.remove("elInputWarning")
        this.element.classList.add('elInputFocused');
        this.labelDisplayer(select.value)
      })

      select.addEventListener('blur', () => {
        this.element.classList.remove('elInputFocused');
        this.labelDisplayer(select.value)
      })

      select.addEventListener('change', () => {
        this.labelDisplayer(select.value)
      })
    }



}

window["SelectBoxV2"] = SelectBoxV2

