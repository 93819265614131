
          
          
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'

  class InputValidatorV1 extends CF2ComponentSingleton {
    phoneErrorMap = [
      "Invalid number",
      "Invalid country code",
      "Too short",
      "Too long",
      "Invalid number",
    ]

    #checkInputPatternValid(input) {
      const validityState = input.validity;
      const $inputParent = $(input).closest('.elFormItemWrapper');
      if (validityState.patternMismatch) {
        input.setCustomValidity('Contains invalid characters');
        input.reportValidity();
        $inputParent.removeClass("elInputValid");
        $inputParent.addClass("elInputError");
        return false;
      } else {
        $inputParent.removeClass("elInputError");
        $inputParent.addClass("elInputValid");
        return true;
      }
    }

    #checkEmailInputValid(input) {
      const re = /^(([^<>()[\]\.,;:#%\s@"]+(\.[^<>()[\]\.,;:#%\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const value = $(input).val();
      const parsedEmail = $.trim(value);
      const $inputParent = $(input).closest('.elFormItemWrapper');
      if (re.test(parsedEmail)) {
        $inputParent.removeClass("elInputError");
        $inputParent.addClass("elInputValid");
        return true;
      } else {
        $inputParent.removeClass("elInputValid");
        $inputParent.addClass("elInputError");
        return false;
      }
    }

     resetInputErrors (input) {
      const $inputParent = $(input).closest('.elFormItemWrapper');
      const $status = $inputParent.find('[data-input-status-type]');
      $inputParent.removeClass('elInputError');
      $inputParent.css('border-color', '');
      $inputParent.css('border-width', '');
      $status.attr('data-input-status-type', '');
      $status.text('');
    }

    resetErrorsAndCheckPhoneNumber(input) {
      this.resetInputErrors(input);
      const value = input.value.trim();
      if (value) {
        const $inputParent = $(input).closest(".elFormItemWrapper");
        const $status = $inputParent.find('[data-input-status-type]');
        if (input.iti && !input.iti.isValidNumber()) {
          const errorCode = input.iti.getValidationError();
          $inputParent.addClass('elInputError');
          $inputParent.removeClass('elInputValid');
          $status.attr('data-input-status-type', 'error');
          $status.text(this.phoneErrorMap[errorCode]);
          return false;
        } else {
          this.resetInputErrors(input);
        }
      }
      return true
    }

    validateInput(input) {
      const $input = $(input);
      const $inputParent = $input.closest(".elFormItemWrapper");
      const inputValue = $input.is('select') ? $input.find(':selected').attr('value') : $input.val();
      const inputName = $input.attr("name");
      const inputType = $input.attr("type")
      const inputPattern = $input.attr("pattern");
      if ($input.hasClass('required1') && inputType == "checkbox") {
        if ($input.is(':checked')) {
          $inputParent.removeClass("elInputError");
          $inputParent.addClass("elInputValid");
          return true
        } else {
          $inputParent.removeClass("elInputValid");
          $inputParent.addClass("elInputError");
          return false
        }
      } else if ($input.hasClass('required1') && inputValue == "" || inputValue == null || typeof inputValue == 'undefined') {
        $inputParent.removeClass("elInputValid");
        $inputParent.addClass("elInputError");
        return false;
      } else {
        if (inputName == "email") {
          return this.#checkEmailInputValid(input)
        } else if (inputName == 'phone_number') {
          if (this.resetErrorsAndCheckPhoneNumber(input)) {
            $inputParent.removeClass("elInputError");
            $inputParent.addClass("elInputValid");
            return true;
          } else {
            $inputParent.addClass('elInputError');
            $inputParent.removeClass('elInputValid');
            return false;
          }
        } else {
          if (!!inputPattern) {
            return this.#checkInputPatternValid(input);
          } else {
            $inputParent.removeClass("elInputError");
            $inputParent.addClass("elInputValid");
            return true
          }
        }
      }
    }
  }
const inputValidator = InputValidatorV1.getInstance();
export default inputValidator;
        